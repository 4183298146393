<template>
  <div class="dev-right-bg">
    <div class="c-schart c-study-wrap">
      <div class="dev-title-jks">
        <span class="dev-title-jks_test">今日值班</span>

        <div
          class="dev-dwcg"
          type="primary"
          @click="handleDwcg()"
        >
          <img
            class="dev-cg-img"
            src="/monitor/images/tj/cg.svg"
          >
          <span>单位查岗</span>
        </div>
      </div>
      <div>
        <div class="c-study__item">
          <span class="c-study__label">姓名：</span>
          <span class="c-study__content">{{ duty.name }}</span>
        </div>
        <div class="c-study__item">
          <span class="c-study__label">电话：</span>
          <span class="c-study__content">{{ duty.tel }}</span>
        </div>

        <div class="c-study__item">
          <span class="c-study__label">执业资格：</span>
          <span class="c-study__content">{{ duty.zyzg }}</span>
        </div>
        <div class="c-study__item">
          <span class="c-study__label">证书编号：</span>
          <span class="c-study__content">{{ duty.zsbh }}</span>
        </div>

        <div class="c-study__item">
          <span class="c-study__label">到岗时间：</span>
          <span class="c-study__content">{{ duty.dgsj | date('yyyy-MM-dd HH:mm:ss') }}</span>
        </div>
      </div>
      <div class="c-schart c-spra-wrap">
        <!--        <div class="c-schart__header u-font-PFSC">-->
        <!--          <sub-title inner-text="火警信息" />-->
        <!--        </div>-->
        <div class="dev-title-jks">
          <span class="dev-title-jks_test">警报信息</span>
        </div>
        <div
          v-if="code === '2c9d809c8b5c3783018b6ac8bd3d0135'"
          class="swiper-container"
        >
          <div class="swiper-wrapper">
            <div
              key="1"
              class="swiper-slide"
            >
              <dqhz-box
                :dqxx-info="dqxxInfo"
              />
            </div>
            <div
              key="2"
              class="swiper-slide"
            >
              <sbgz-box
                :sbxx-info="sbxxInfo"
              />
            </div>
            <div
              key="3"
              class="swiper-slide"
            >
              <today-fire
                :hjxx-info="hjxxInfo"
              />
            </div>
          </div>
        </div>

        <div
          v-if="code !== '2c9d809c8b5c3783018b6ac8bd3d0135'"
          class="swiper-container"
        >
          <div class="swiper-wrapper">
            <div
              key="2"
              class="swiper-slide"
            >
              <sbgz-box
                :sbxx-info="sbxxInfo"
              />
            </div>
            <div
              key="3"
              class="swiper-slide"
            >
              <today-fire
                :hjxx-info="hjxxInfo"
              />
            </div>
            <div
              key="1"
              class="swiper-slide"
            >
              <dqhz-box
                :dqxx-info="dqxxInfo"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="c-schart c-spra-wrap">
        <div class="dev-title-jks">
          <span class="dev-title-jks_test">管网压力</span>
        </div>
        <gwyl-chart
          ref="gwylChart"
          :style="'width:100%;height: 190px;'"
          :code="code"
          ssbm="A53,A54,A68"
          class="dev-c-dCard__body"
        />
      </div>

      <div class="c-schart c-spra-wrap">
        <div
          class="dev-title-jks"
          style="margin-top: 10px"
        >
          <span
            class="dev-title-jks_test"
          >水位监控</span>
        </div>

        <!--        <swjk-chart-->
        <!--          ref="swjkChart"-->
        <!--          :style="'width:100%;height: 190px;margin-top: 10px;'"-->
        <!--          :code="code"-->
        <!--          ssbm="A35"-->
        <!--          class="dev-c-dCard__body"-->
        <!--        />-->
        <sq-chart
          ref="swChart"
          :code="code"
          ssbm="A35"
          class="dev-c-dCard__body"
        />
      </div>
    </div>
  </div>
</template>

<script>
// import SubTitle from '../components/SubTitle.vue';
import { virtualService } from '../virtual.service';
// import SwjkChart from './swjkChart.vue';
import GwylChart from './gwylChart.vue';
import SqChart from './sqChartTj.vue';
import TodayFire from '../../monitor/components/TodayFire.vue';
import DqhzBox from '../../monitor/components/Dqhz.vue';
import SbgzBox from '../../monitor/components/Sbgz.vue';
import EventBus from '@/event-bus';
import Swiper from 'swiper';
/* eslint-disable */
import "swiper/css/swiper.min.css";
export default {
  name: 'ChartBox',
  components: {
    // SubTitle,
    // SwjkChart,
    GwylChart,
    SqChart,
    TodayFire,
    // Swiper,
    DqhzBox,
    SbgzBox
  },
  props: {
    // 联网单位
    code: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      mySwiper:null,
      clock: null,

      // activeMonitoringRoom: '',
      // ylLxDms: ['A53', 'A68'], // 压力类型代码列表
      // sllLxDms: ['A35'], // 管网水流量
      ssbm: 'A35',
      ylLxDmsSSbm: 'A53',
      // 监控室
      // monitoringRoomList: [
      //   {
      //     code: 'gzwzds',
      //     name: '广州物资大厦',
      //     yxsc: '1512.9'
      //   },
      //   {
      //     code: 'hm',
      //     name: '鸿蒙智慧消防实验室',
      //     yxsc: '926'
      //   },
      //   {
      //     code: 'dd1',
      //     name: '待定'
      //   },
      //   {
      //     code: 'dd2',
      //     name: '待定'
      //   }
      // ],
      // 今日值班
      dutyList: [
        // {
        //   code: '1',
        //   name: '黄光健',
        //   tel: 13288679012,
        //   zyzg: '四级消防设施操作员',
        //   zsbh: '1936003008400373',
        //   dgsj: '2022-09-09'
        // }
      ],
      duty: {},
      // 火警信息
      hjxxInfo: {

      },
      dqxxInfo: {
        // 电器信息

      },
      sbxxInfo: {
        // 设备信息

      },
      swiperOption: {
        // 循环
        loop: true,
        // 设定初始化时slide的索引
        initialSlide: 0,
        // 自动播放，等同下面autoplay设置
        // autoplay: true, // 可选选项，自动滑动
        autoplay: {
          disableOnInteraction: false,
          delay: 3000
        },
        // 设置轮播切换效果
        effect: 'slide',
        // 轮播切换动速度
        speed: 800,
        // 轮播滑动方向
        direction: 'horizontal',
        observer: true, // 修改swiper自己或子元素时，自动初始化swiper
        observeParents: true // 修改swiper的父元素时，自动初始化swiper
      }
    };
  },
  methods: {
    queryZbry () {
      virtualService.queryZbry(this.code)
        .then(rsp => {
          this.dutyList = rsp.data;
          this.duty = this.dutyList[0] ? this.dutyList[0] : {};
        })
        .catch(err => {
          console.error('获取值班人员信息错误！', err);
        });
    },
    queryHjxx () {
      virtualService.queryHjxx(this.code)
        .then(rsp => {
          this.hjxxInfo = rsp.data;
        })
        .catch(err => {
          console.error('获取火警信息错误！', err);
        });
    },
    // 当前单位火警和设备故障和电气火灾信息
    queryJbTj() {
      virtualService.queryJbTj(this.code)
        .then(rsp => {
          this.sbxxInfo = rsp.data.sbxx;
          this.hjxxInfo = rsp.data.hjxx;
          this.dqxxInfo = rsp.data.dqxx;
          console.log("this.hjxxInfo",rsp.data);

          this.initSwiper();
        })
        .catch(err => {
          console.error('获取设备信息错误！', err);
        });
    },
    handleDwcg () {
      EventBus.$emit('Dpdwcg', this.code);
    },
    initSwiper () {
      const _this = this;
      /* eslint-disable */
      if (this.mySwiper) {
        this.mySwiper.destroy();
        this.mySwiper = null;
      }


      this.mySwiper = new Swiper(".swiper-container", {
        loop: false,
        // 设定初始化时slide的索引
        // initialSlide: 1,
        autoplay: {
          disableOnInteraction: false,
          delay: 5000
        },
        // 设置轮播切换效果
        effect: 'slide',
        // 轮播切换动速度
        speed: 800,
        // 轮播滑动方向
        direction: 'horizontal',
        observer: false, // 修改swiper自己或子元素时，自动初始化swiper
        observeParents: true // 修改swiper的父元素时，自动初始化swiper
      });
    },
    rwTask(){
      if (this.code) {
        this.queryZbry();
        this.queryJbTj();

      }
    }
  },
  watch: {
    code (newVal, oldVal) {
      const _this=this;
      this.rwTask()
    }
  },
  mounted () {

  },
  created(){
  },
  destroyed(){
    clearInterval(this.clock);
    if (this.mySwiper) {
      this.mySwiper.destroy();
      this.mySwiper = null;
    }
  }
};
</script>

<style scoped lang="scss">
@import './chart.scss';
.dev-right-bg{
  width: 402px;
  height: 954px;
  padding: 20px;
  background: url('/monitor/images/tj/right-bg.png');

}
.dev-title-jks{
  display: flex;
  align-items: center;
  position: relative;
  width: 329px;
  height: 30px;
  background: url('/monitor/images/tj/left-jks.png');

  &_test{
    margin-top: -7px;

    margin-left: 25px;
    font-family: HYYaKuHei-85J;
    font-weight: 85J;
    font-size: 16px;
    color: #FFFFFF;
  }

}

.c-study__item{
  display: flex;
  margin:15px 0;
  align-items: center;
  &::before {
    content: '';
    display: inline-block;
    width: 21px;
    height: 15px;
    margin-right: 8px;
    background: url('/monitor/images/tj/bottom-jz.png');
    top: 3px;
    position: relative;
  }
}
.c-study__body{
  display: flex;
}
.c-study__label{
  font-family: AlibabaPuHuiTi_2_55_Regular;
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
}
.c-study__content{
  font-family: AlibabaPuHuiTi_2_55_Regular;
  font-weight: 400;
  font-size: 14px;
  color: #00CEFF
}

.dev-dwcg{
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 103px;
  height: 32px;
  position: absolute;
  right: -20px;
  border: 1px solid #03A9F4;
  text-align: center;
  line-height: 32px;
  background: #012f73;
  border-radius: 4px;

  font-family: AlibabaPuHuiTi_2_85_Bold;
  font-weight: 700;
  font-size: 14px;
  background: #002F72;
  -webkit-box-shadow: inset 0 0 8px 0 #0066ff80;
  box-shadow: inset 0 0 8px 0 #00D1FE;
  color: #ffff;

}
.dev-dwcg:hover{
  border: 1px solid #eaece9;
}
.dev-cg-img{
  width: 24px;
  height: 24px;
  margin: 0 5px;
}

</style>
