<template>
  <div
    v-if="showNpl ||showNplTips"
    style="height: 100%;"
  >
    <div
      v-if="showNpl"
      class="npl-contain"
    >
      <div class="npl-header" />
      <div class="npl-content">
        <component
          :is="comName"
          :key="comId"
          :dw-list="dwList"
          :sel-dw="selDw"
          :sel-quest="selQuest"
        />
      </div>

      <div class="npl-foot" />
    </div>

    <div
      v-if="showNplTips"
      class="npl-contain"
    >
      <div class="npl-zb-model">
        <img
          style="height: 100%;"
          src="/3DModel/npl/women.png"
        >
      </div>
      <div class="npl-tips">
        <div class="npl-tips__title">
          您可以这样说：
        </div>
        <div class="npl-tips__labs">
          <div
            v-for="(item) in nplConfig"
            :key="item.id"
            class="npl-tips__labs__lab dev-click"
            @click="clickZl(item.lab)"
          >
            {{ item.lab }}
          </div>
        </div>
        <div class="npl-tips__Text">
          {{ nplTips }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NplDwxf from './npl-dwxf.vue';
import NplDw from './npl-dw.vue';
import NplZb from './npl-zb.vue';
import NplXfswg from './npl-xfswg.vue';
import NplXfxj from './npl-xfxj.vue';
import NplXfjc from './npl-xfjc.vue';
import NplXfby from './npl-xfby.vue';
// import { nplService } from './npl.service';
import { QWebChannel } from './qwebchannel';
// import nplModelVue from './npl-model.vue';
import { nplService, iffService } from './npl.service';
import EventBus from '@/event-bus';
export default {
  name: 'Npl',
  components: {
    NplDwxf,
    NplDw,
    NplZb,
    NplXfswg,
    NplXfxj,
    NplXfjc,
    NplXfby
  },
  props: {
    dwList: {
      type: Array,
      default: () => {
        return [
        ];
      }
    },
    isFireWarn: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      comName: 'NplDwxf',
      comId: null,
      isListen: false,
      stateListen: '',
      showNpl: false,
      showNplTips: false,
      nplTips: '我正在听...',
      mainApp: null, // 语音唤醒API
      nplConfig: null, // 语音识别规则
      selDw: null, // 语音所选单位
      selQuest: null, // 语音问题
      loadingCj: null

    };
  },
  methods: {
    listen () {
      const _this = this;
      // _this.showNpl = false;
      if (!this.isListen && !this.isFireWarn) {
        _this.isListen = true;
        _this.showNplTips = true;
        nplService.play('我在', () => {
          _this.doListen();
        });
      }
    },
    // 关闭麦克风
    recorderOut () {
      if (window.localStream) {
        window.localStream.getTracks().forEach(track => track.stop());
      }
    },
    doListen () {
      const _this = this;
      _this.stateListen = '';
      nplService.listen((text) => {
        // 显示识别文字
        _this.stateListen !== 'end' && (_this.nplTips = text || '我正在听...');
      }, (_text) => {
        // 识别结束
        _this.stateListen = 'end';
        if (!_this.showNplTips) {
          // 关闭人机对话，关闭麦克风
          _this.recorderOut();
          return;
        }
        const instructJqr = new RegExp('(系统介绍|介绍系统)').test(_text);
        if (instructJqr) {
          // 发送指令
          nplService.stopListen();
          _this.resetListen();
          _this.recorderOut();
        }

        const exitCom = new RegExp('^(退出|返回|关闭)').test(_text);
        if (exitCom) {
          // 返回首页
          _this.showNpl = false;
          nplService.play('好的', () => {
            _this.resetListen();
            // 关闭人机对话，关闭麦克风
            _this.recorderOut();
          });
          return;
        }
        this.getZjm(_text);
      });
    },

    /**
     * 获取数据库配置得指令词
     */
    getZjm (_text) {
      const _this = this;
      let zjm = '';
      iffService.recognizeText({ text: _text })
        .then(rsp => {
          zjm = rsp.data.zjm;
          _this.showNplTips = !zjm;
          _this.showNpl = zjm;
          if (rsp.data && rsp.data.params && rsp.data.params.ssdw[0] && rsp.data.params.ssdw[0] !== undefined) {
            this.selDw = this.dwList.find(item => {
              if (new RegExp(`^.*${item.code}.*$`).test(rsp.data.params.ssdw[0])) return item;
            });// 匹配被选择单位
          }

          if (zjm) {
            this.selQuest = _text;
            _this.comName = zjm; // 切换表报
            _this.comId = new Date();
            // 识别成功，关闭麦克风
            _this.recorderOut();
          } else {
            this.handleListenFail(_text);
          }
        })
        .catch(err => {
          console.error('语音数据分析读取失败！', err);
        })
        .finally(() => {
          this.loadingCj.close();
          this.loading = false;
        });
    },
    /**
     * 点击对话指令标签跳转场景
     */
    clickZl (_text) {
      const _this = this;
      _this.loadingCj = this.$loading({
        lock: true,
        text: '场景加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      console.log(_text);
      this.getZjm(_text);
    },
    /**
     * @description: 接听失败处理
     * @return {*}
     * @param {*} text 文本
     */
    handleListenFail (text) {
      console.log(text);
      const _this = this;

      // 识别失败
      if (_this.isFireWarn) {
        // 有新火警
        _this.resetListen();
        return;
      }
      const isNullText = !text || text === '';
      const failTips = isNullText ? '对不起，没听到你说话，请重试' : '对不起，没理解，请重试';
      _this.nplTips = failTips;
      this.$refs.NplMode.playUnknow();
      nplService.play(failTips, () => {
        isNullText && _this.resetListen();
        !isNullText && _this.doListen();
      });
    },
    change () {
      // this.selQuest = '我的问题';
      // this.selDw = this.dwList[0];
      // this.showNpl = true;
      this.resetListen();
      let i = this.nplConfig.findIndex(item => item.name === this.comName);
      i = i === this.nplConfig.length - 1 ? 0 : i + 1;
      this.comName = this.nplConfig[i].name;
    },
    resetListen () {
      this.isListen && nplService.stopListen();
      this.showNplTips = false;
      this.isListen = false;
      this.nplTips = '我正在听...';
      if (this.mainApp) {
        // 重启启动语音唤醒
        this.mainApp.startWait4WakenUp();
      }
    },
    computeNplConfig () {
      // 单位名称正则表达式
      const dwEeg = this.dwList.reduce((total, item, index) => {
        if (index !== 0) total += '|';
        return total + item.name;
      }, '');
      this.nplConfig = [
        {
          lab: '“查看所有单位的消防情况”',
          name: 'NplDwxf',
          question: new RegExp('^[看|查].*所有单位.*消防情况.{0,1}$')
        },
        {
          lab: '“查看所有单位的消防巡查情况”',
          name: 'NplXfxj',
          question: new RegExp('^[看|查].*所有单位.*消防巡查情况.{0,1}$')
        },
        {
          lab: '“查看所有单位的消防检测情况”',
          name: 'NplXfjc',
          question: new RegExp('^[看|查].*所有单位.*消防检测情况.{0,1}$')
        },
        // {
        //   lab: '“查看所有单位的设施保养情况”',
        //   name: 'NplXfby',
        //   question: new RegExp('^[看|查].*所有单位.*设施保养情况.{0,1}$')
        // },
        {
          lab: `查看${this.dwList[0] ? this.dwList[0].name : 'xx单位'}的消防情况`,
          name: 'NplDw'
          // question: new RegExp(`^[看|查].*(${dwEeg}).*消防情况.{0,1}$`)
        },
        {
          lab: `“查看${this.dwList[0] ? this.dwList[0].name : 'xx单位'}今日的值班情况”`,
          name: 'NplZb',
          question: new RegExp(`^[看|查].*(${dwEeg}).*值班情况.{0,1}$`)
        },
        {
          lab: `“查看${this.dwList[0] ? this.dwList[0].name : 'xx单位'}的水管网情况”`,
          name: 'NplXfswg',
          question: new RegExp(`^[看|查].*(${dwEeg}).*水管网情况.{0,1}$`)
        }
      ];
    },
    playVoice (str) {
      // 播放文字语音
      const _this = this;
      _this.isListen = true;
      nplService.stopPlay();
      nplService.play(str, () => {
        _this.resetListen();
      });
    }
  },
  watch: {
    dwList (newVal, oldVal) {
      this.computeNplConfig();
    },
    isFireWarn (newVal, oldVal) {
      // 有新火警
      newVal && this.isListen && nplService.stopPlay();
      newVal && this.resetListen();
    }
  },
  created () {
    console.log('重新唤醒');
    this.computeNplConfig();
    const _this = this;
    EventBus.$on('setCloseNpl', function (data) {
      _this.closeNpl = data;
      if (!data) {
        // 重启启动语音唤醒
        _this.reconnectMinApp();
      }
    });

    // 定时重新连接
    setInterval(() => {
      // 重启启动语音唤醒
      _this.reconnectMinApp();
    }, 1000 * 180);

    EventBus.$on('openYysb', function (data) {
      _this.listen();
    });

    EventBus.$on('stopPlay', function (data) {
      console.log('stopPlay');
      _this.resetNpl();
    });

    // 加载语音唤醒API
    // eslint-disable-next-line no-new, no-undef
    new QWebChannel(qt.webChannelTransport, function (channel) {
      _this.mainApp = channel.objects.mainApp;
    });
  }
};
</script>

<style scoped lang="scss">

.npl-contain {
  height: 90%;
  margin: 11px;
  border: solid 1px #2667ae;
  text-align: center;
  background-image: linear-gradient(269deg, rgba(14, 33, 70, 0.24) 0%, rgba(12, 29, 63, 0.64) 10%, rgba(0, 9, 28, 0.72) 99%);
}

.npl-header {
  width: 100%;
  height: 6%;
  background: url('/3DModel/npl/header.png') no-repeat center center;
  background-size: cover;
}

.npl-content {
  width: 100%;
  height: 88%;
}

.npl-foot {
  width: 100%;
  height: 6%;
  background: url('/3DModel/npl/foot.png') no-repeat center center;
  background-size: cover;
}

.npl-tips {
  position: fixed;
  bottom: 0;
  box-sizing: border-box;
  width: 100%;
  height: 180px;
  padding: 20px;
  text-align: left;
  background-color: rgba(0, 0, 0, 0.7);

  &__title {
    font-size: 20px;
    color: rgb(255, 255, 255);
  }

  &__labs {
    display: inline-block;
    margin: 10px 0;

    &__lab {
      display: inline-block;
      padding: 10px 20px;
      margin-right: 10px;
      border-radius: 25px;
      font-size: 16px;
      color: #fff;
      text-align: center;
    }

    & &__lab:last-child {
      margin-top: 10px;
    }

    & &__lab:nth-child(4n+1) {
      background-color: #34abfa;
    }

    & &__lab:nth-child(4n+2) {
      background-color: #92abf5;
    }

    & &__lab:nth-child(4n+3) {
      background-color: #f8bd45;
    }

    & &__lab:nth-child(4n+4) {
      background-color: #30b4bb;
    }

  }

  &__Text {
    margin-top: 10px;
    font-family: MicrosoftYaHei;
    font-size: 20px;
    color: #fff;
  }
}

.dev-click:hover{
  border: 1.5px solid #379ffe;
  box-shadow: 0 0 10px 2.5px rgb(55 159 254 / 100%);
  font-size: 17px;
}
</style>
