<!--
 * @Author: zxf
 * @Date: 2023-10-16 15:19:15
 * @LastEditors: zxf
 * @LastEditTime: 2023-12-23 16:51:53
 * @Description: 设备故障
-->
<template>
  <div class="u-fire-contains">
    <div class="u-fire-today">
      <img
        id="todoy"
        src="/images/monitor/hjSanjiao.png"
      >
      <div style="margin-left: 6px;">
        <span class="u-fire-today_num">{{ sbxxInfo.gz?sbxxInfo.gz:0 }}</span>
        <span class="u-fire-today_label">设备故障</span>
      </div>
    </div>
    <div class="u-fire-circle">
      <div class="u-fire-zc">
        <div style="margin-left: 6px;">
          <span class="u-fire-circle__num">{{ sbxxInfo.zc?sbxxInfo.zc:0 }}</span>
          <span class="u-fire-circle__label">正常</span>
        </div>
      </div>
      <div class="u-fire-wb">
        <div style="margin-left: 6px;">
          <span class="u-fire-circle__num">{{ sbxxInfo.wb?sbxxInfo.wb:0 }}</span>
          <span class="u-fire-circle__label">误报</span>
        </div>
      </div>
      <div class="u-fire-gz">
        <div style="margin-left: 6px;">
          <span class="u-fire-circle__num">{{ sbxxInfo.wbsl?sbxxInfo.wbsl:0 }}</span>
          <span class="u-fire-circle__label">维保数量</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SbBox',
  props: {
    sbxxInfo: {
      type: Object,
      default: () => { return null; }
    }
  }
};
</script>

<style scoped lang="scss">
.u-fire-contains{
  height: 220px;
  background: url(/images/monitor/todayFire.png) no-repeat center center;
}

.u-fire-today{
  display: flex;
  position: relative;
  top: 44px;
  left: 34%;

  &_label{
    display: block;
    width: 56px;
    height: 12px;
    font-family: AlibabaPuHuiTi_2_85_Bold;
    font-size: 14px;
    font-weight: 700;
    line-height: 12px;
    color: #66F9FF;
    text-align: center;
  }

  &_num{
    padding-left: 4px;
    font-family: PangMenZhengDao;
    font-size: 22px;
    line-height: 28px;
    color: #FFF;
    text-align: center;
  }
}

.u-fire-circle{
  position: relative;
  top: 56px;

  &__num{
    display: block;
    width: 50px;
    height: 50px;
    font-family: PangMenZhengDao;
    font-size: 16px;
    line-height: 50px;
    letter-spacing: 0;
    color: #3DFBFF;
    text-align: center;
    background: url(/images/monitor/hjYuan.png) no-repeat;
  }

  &__label{
    display: block;
    width: 58px;
    height: 28px;
    margin-top: 6px;
    font-family: AlibabaPuHuiTi_2_55_Regular;
    font-size: 12px;
    font-weight: 400;
    line-height: 28px;
    color: #FFF;
    text-align: center;
    background: url(/images/monitor/hjLabel.png) no-repeat left center;
  }
}

.u-fire-zc{
  position: absolute;
  left: 10%;
}

.u-fire-wb{
  position: absolute;
  top: 12px;
  left: 40%;
}

.u-fire-gz{
  position: absolute;
  left: 70%;
}
</style>
